import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import moment1 from "moment-timezone";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MultiSelect from "multiselect-react-dropdown";
export default () => {
  const handleOrderClick = (data) => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = `/order-details/${data.orderNum}`;
    a.click();
  };

  return (
    <div className="formWrapper">
      <Table handleOrderClick={handleOrderClick} />
    </div>
  );
};

const OrderNoBtn = (props) => {
  const handleBtnClick = () => {
    props.handleOrderClick(props.data);
  };
  return (
    <button type="button" className="btn-link" onClick={handleBtnClick}>
      {props.title}
    </button>
  );
};

const Table = (props) => {
  const [dataList, setDataList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [filter, setFilter] = useState({});

  const columns = [
    {
      key: "soClassification",
      name: "SO Classification",
      colSize: "w200",
      type: "string",
      isSortable: true,
    },

    {
      key: "btPatientId",
      name: "BT-Id",
      colSize: "w100",
      type: "string",
      isSortable: true,
    },
    {
      key: "referringFacility",
      name: "Referring Facility",
      colSize: "w200",
      type: "string",
      isSortable: true,
    },

    {
      key: "orderingDoctor",
      name: "Ordering Doctor",
      colSize: "w200",
      type: "string",
      isSortable: true,
    },
    {
      key: "primaryPayer",
      name: "Primary Payor",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },

    {
      key: "marketingRep",
      name: "MSS",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },

    {
      key: "orderNum",
      name: "Order No",
      colSize: "w100",
      type: "string",
      children: OrderNoBtn,
      isSortable: true,
    },
    {
      key: "status",
      name: "Status",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },

    {
      key: "patientName",
      name: "Patient Name",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },

    {
      key: "orderReceivedDate",
      name: "Received date",
      colSize: "w150",
      type: "date",
      isSortable: true,
    },

    {
      key: "shippingDate",
      name: "Shipping Date",
      colSize: "w200",
      type: "date",
      isSortable: true,
    },

    {
      key: "assignedTo",
      name: "Assigned To",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },
    {
      key: "hcpcCodes",
      name: "HCPCS",
      colSize: "w100",
      type: "array",
      isSortable: true,
    },

    {
      key: "facilityState",
      name: "Facility State",
      colSize: "w200",
      type: "string",
      isSortable: true,
    },
    {
      key: "wipStatus",
      name: "WIP Status",
      colSize: "w150",
      type: "string",
      isSortable: true,
    },
  ];

  useEffect(() => {
    setLoader(true);
    const userDetails = localStorage.getItem("userDetails");
    const userDetailsParse = JSON.parse(userDetails);
    console.log("userDetailsParse =>", userDetailsParse);
    if (!userDetails) {
      return;
    }
    axios
      .get(`/api/orders/get/list/${userDetailsParse.user_id}`)
      .then((response) => {
        setPageLimit(response.data.orderFilter.pageLimit ?? 10);
        delete response.data.orderFilter.pageLimit;
        setFilter(response.data.orderFilter);
        setDataList(
          response.data.orders
            .filter((order) => order.state != "NA")
            .map((o) => {
              return {
                ...o,
                hcpcCodes: o.hcpcCodes.filter((o1) => o1 != "" && o1 != null),
              };
            })
            .sort((a, b) => {
              return a.orderNum && b.orderNum
                ? b.orderNum - a.orderNum
                : a.orderNum === undefined
                ? 0
                : -1;
            })
        );
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        swal(err);
      });
  }, []);

  const [page, setPage] = useState(1);
  let pages = 2;
  let dataRows1 = [];
  const getPaginatedEntries = () => {
    dataRows1 = dataList.filter((entry) => {
      let isValidRow = true;
      if (filter["fromDate"] || filter["toDate"]) {
        const orderReceivedDate = entry["orderReceivedDate"]
          ? moment(entry["orderReceivedDate"]).format("YYYY-MM-DD")
          : 0;
        const fromDate =
          filter["fromDate"] !== undefined
            ? moment(filter["fromDate"]).format("YYYY-MM-DD")
            : orderReceivedDate;
        const toDate =
          filter["toDate"] !== undefined
            ? moment(filter["toDate"]).format("YYYY-MM-DD")
            : moment(Date.now()).format("YYYY-MM-DD");
        isValidRow =
          orderReceivedDate >= fromDate && orderReceivedDate <= toDate;
      }
      if (filter["shippingDate"]) {
        const shippingDate = entry["shippingDate"]
          ? moment(entry["shippingDate"]).format("MM-DD-YYYY")
          : 0;
        isValidRow =
          (filter["shippingDate"] == "NA" && entry["shippingDate"] == null) ||
          filter["shippingDate"].includes(shippingDate);
      }

      if (Object.keys(filter).length === -1) return isValidRow;

      return (
        isValidRow &&
        Object.entries(filter)
          .filter(
            (data) =>
              data[0] !== "fromDate" &&
              data[0] !== "toDate" &&
              data[0] !== "shippingDate" &&
              data[0] !== "search" &&
              data[1] !== "" &&
              data[0] !== "sort"
          )
          .every((data) => data[1]?.includes(entry[data[0]])) &&
        (filter.search !== undefined
          ? entry["orderNum"]
              ?.toString()
              .includes(filter?.search.toLowerCase()) ||
            entry["btPatientId"]?.toString().includes(filter?.search) ||
            entry["marketingRep"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["patientName"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["assignedTo"]?.includes(filter?.search) ||
            entry["referringFacility"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["hcpcCodes"]?.toString().includes(filter?.search) ||
            entry["wipStatus"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["status"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["soClassification"]
              ?.toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            entry["hcpcCodes"]
              ?.filter((o) => o != null || o != "")
              .join(",")
              .toLowerCase()
              .includes(filter?.search.toLowerCase()) ||
            (entry["shippingDate"] == null &&
              filter?.search?.toLowerCase() == "na")
          : true)
      );
    });

    pages = dataRows1.length > 0 ? dataRows1.length - 1 : 1;
    return dataRows1
      .sort((a, b, index) => {
        const v1 =
          typeof a[filter?.sort?.key] == "object" && a[filter?.sort?.key]
            ? a[filter?.sort?.key]?.join(",")
            : a[filter?.sort?.key] ?? 0;
        const v2 =
          typeof b[filter?.sort?.key] == "object" && b[filter?.sort?.key]
            ? b[filter?.sort?.key]?.join(",")
            : b[filter?.sort?.key] ?? 0;

        if (v1 == 0) {
          return 1;
        }
        if (v2 == 0) {
          return -1;
        }

        if (isNaN(v1) && isNaN(v2))
          return filter?.sort?.type === 1
            ? v1?.localeCompare(v2)
            : v2?.localeCompare(v1);
        return filter?.sort?.type === 0 ? v1 - v2 : v2 - v1;
      })
      .slice((page - 1) * pageLimit, page * pageLimit);
  };

  const handlePageLimit = (e) => {
    setPageLimit(parseInt(e.target.value));
  };

  const dataRows = getPaginatedEntries();

  const handleSort = (event) => {
    setFilter({
      ...filter,
      sort: { key: event.target.id, type: filter?.sort?.type === 1 ? 0 : 1 },
    });
  };

  return (
    <div className="pageContent px-5  pt-1">
      <div className="row justify">
        <div className="col-12 pt-4 pageHeader">Order List</div>
        <hr className="hr-head mt-0 mb-5 container-fluid row" />
        <FilterComponent
          handlePageLimit={handlePageLimit}
          pageLimit={pageLimit}
          filter={filter}
          handleFilter={setFilter}
          setPage={setPage}
          dataList={dataList}
          columns={columns}
        />
        <div className="pt-3 pl-0 col-12 overflow-auto scroll-auto">
          <table className="table table-hover table-bordered table-striped table-height tableFixHead">
            <thead className="tempTableHeader">
              <tr>
                <th scope="col">#</th>
                {columns.map((column) => (
                  <th
                    scope="col"
                    className={`${column.colSize} ${
                      filter?.sort?.key == column.key && "text-primary"
                    }  text-uppercase `}
                  >
                    {column.name}
                    {column.isSortable && (
                      <i
                        id={column.key}
                        value={column.key}
                        className={`fa fa-fw  ${
                          column.key != filter?.sort?.key && "fa-sort"
                        } ${
                          column.key == filter?.sort?.key &&
                          (filter?.sort?.type == 1
                            ? "fa-arrow-up text-warning"
                            : "fa-arrow-down text-warning")
                        }`}
                        onClick={handleSort}
                      ></i>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataRows.length > 0 ? (
                dataRows.map((data, index) => (
                  <tr key={index}>
                    <td className="col-md-4 col-sm-4, col-lg-4 text-center align-middle">
                      {page === 1
                        ? index + 1
                        : `${
                            (page == 1 ? 1 : page - 1) * pageLimit + (index + 1)
                          }`}
                    </td>
                    {columns.map((column, index) => (
                      <td
                        key={index}
                        className={`col-md-4 col-sm-4, col-lg-4 ${
                          column.key == "status" ? "text-center" : "text-left"
                        } align-middle`}
                      >
                        {column.children ? (
                          <column.children
                            handleOrderClick={props.handleOrderClick}
                            data={data}
                            title={data[column.key] ?? ""}
                          />
                        ) : column.type === "date" ? (
                          data[column.key] ? (
                            moment1(data[column.key]?.split("T")[0])
                              .tz("America/New_York")
                              .format("MM-DD-yyyy")
                          ) : filter?.search?.toLowerCase() == "na" ? (
                            <span style={{ background: "yellow" }}>NA</span>
                          ) : (
                            "NA"
                          )
                        ) : (
                          (column.key == "status" && (
                            <div
                              className={`badge-pill p-1
                                                        ${
                                                          data[column.key] ==
                                                            "RECEIVED" &&
                                                          "badge-primary"
                                                        } 
                                                        ${
                                                          data[column.key] ==
                                                            "SHIPPED" &&
                                                          "badge-success"
                                                        }
                                                        ${
                                                          data[column.key] ==
                                                            "IN PROGRESS" &&
                                                          "badge-info"
                                                        }
                                                        ${
                                                          data[column.key] ==
                                                            "PENDING" &&
                                                          "badge-warning"
                                                        }
                                                        ${
                                                          data[column.key] ==
                                                            "CANCELLED" &&
                                                          "badge-danger"
                                                        }
                                                        ${
                                                          data[column.key] ==
                                                            "FORWARDED" &&
                                                          "badge-secondary"
                                                        }
                                                        
                                                        `}
                            >
                              {data[column.key]}
                            </div>
                          )) ||
                          (column.type == "array" &&
                            (filter.search !== undefined &&
                            filter.search !== "" &&
                            data[column.key]
                              ?.join(",")
                              ?.toLowerCase()
                              ?.includes(filter?.search?.toLowerCase()) ? (
                              <span style={{ background: "yellow" }}>
                                {data[column.key].join(",")}
                              </span>
                            ) : (
                              data[column.key].join(",")
                            ))) ||
                          (isNaN(data[column.key]) &&
                            filter.search !== undefined &&
                            filter.search !== "" &&
                            data[column.key]
                              ?.toLowerCase()
                              .includes(filter?.search?.toLowerCase()) && (
                              <span style={{ background: "yellow" }}>
                                {data[column.key]}
                              </span>
                            )) ||
                          (!isNaN(data[column.key]) &&
                            filter.search !== undefined &&
                            filter.search !== "" &&
                            data[column.key]
                              ?.toString()
                              .includes(filter?.search) && (
                              <span style={{ background: "yellow" }}>
                                {data[column.key]}
                              </span>
                            )) ||
                          data[column.key]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <center className="table-empty container align-middle">
                  {loader ? (
                    <div className="loadingImg">
                      <img src="/images/loading.gif" />
                    </div>
                  ) : (
                    <h3>No Data Available.</h3>
                  )}
                </center>
              )}
            </tbody>
          </table>
        </div>
        <TablePagination
          currentPage={page}
          pages={pages}
          setPage={setPage}
          pageLimit={pageLimit}
          handlePageLimit={handlePageLimit}
        />
      </div>
    </div>
  );
};

const FilterComponent = ({
  filter,
  handleFilter,
  setPage,
  pageLimit,
  handlePageLimit,
  dataList,
}) => {
  const MSSList = [
    ...new Set(
      dataList
        .filter((o) => o.marketingRep != null)
        .map((data) => data.marketingRep)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const OrderingDoctorList = [
    ...new Set(
      dataList
        .filter((o) => o.orderingDoctor != null)
        .map((data) => data.orderingDoctor)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const AssignedToList = [
    ...new Set(
      dataList
        .filter((o) => o.assignedTo != null)
        .map((data) => data.assignedTo)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const StatusList = [
    ...new Set(
      dataList.filter((o) => o.status != null).map((data) => data.status)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const FacilityStateList = [
    ...new Set(
      dataList
        .filter((o) => o.facilityState != null)
        .map((data) => data.facilityState)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const ReferringFacilityList = [
    ...new Set(
      dataList
        .filter((o) => o.referringFacility != null)
        .map((data) => data.referringFacility)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const ShippingDateList = [
    ...new Set(
      dataList
        .filter((o) => o.shippingDate != null)
        .map((data) => moment(data.shippingDate).format("MM-DD-yyyy"))
    ),
  ].sort((a, b) => a?.localeCompare(b));
  const SoClassificationList = [
    ...new Set(
      dataList
        .filter((o) => o.soClassification != null)
        .map((data) => data.soClassification)
    ),
  ].sort((a, b) => a?.localeCompare(b));
  ShippingDateList.unshift("NA");

  const filterList = [
    {
      name: "From Date",
      dataList: [],
      key: "fromDate",
      type: "date",
      multiSelect: false,
      save: false,
    },
    {
      name: "To Date",
      dataList: [],
      key: "toDate",
      type: "date",
      multiSelect: false,
      save: false,
    },
    {
      name: "MSS",
      dataList: MSSList,
      key: "marketingRep",
      multiSelect: true,
      save: true,
    },
    {
      name: "Ordering Doctor",
      dataList: OrderingDoctorList,
      key: "orderingDoctor",
      multiSelect: true,
      save: true,
    },
    {
      name: "Assigned To",
      dataList: AssignedToList,
      key: "assignedTo",
      multiSelect: true,
      save: true,
    },
    {
      name: "Status",
      dataList: StatusList,
      key: "status",
      multiSelect: true,
      save: true,
    },
    {
      name: "Referring Facility",
      dataList: ReferringFacilityList,
      key: "referringFacility",
      multiSelect: true,
      save: true,
    },
    {
      name: "Referring Facility State",
      dataList: FacilityStateList,
      key: "facilityState",
      multiSelect: true,
      save: true,
    },
    {
      name: "Shipping Date",
      dataList: ShippingDateList,
      key: "shippingDate",
      multiSelect: true,
      save: false,
      type: "string",
    },
    {
      name: "SO Classification",
      dataList: SoClassificationList,
      key: "soClassification",
      multiSelect: true,
      save: true,
    },
  ];

  const handleFilterChange = (event) => {
    const filterObj = filterList.find((obj) => obj.key == event.target.name);
    const currentFilter = filter[event.target.name] ?? [];
    const filteredArray = filterObj?.multiSelect
      ? currentFilter.includes(event.target.value)
        ? {
            ...filter,
            [event.target.name]: currentFilter.filter(
              (fltr) => fltr !== event.target.value
            ),
          }
        : {
            ...filter,
            [event.target.name]: [...currentFilter, event.target.value],
          }
      : { ...filter, [event.target.name]: event.target.value };
    if (
      filterObj?.multiSelect &&
      filteredArray[event.target.name].length === 0
    ) {
      delete filteredArray[event.target.name];
    }

    handleFilter(filteredArray);
    filterObj.save &&
      setOrderFilterPreferences({ ...filteredArray, pageLimit });
    setPage(1);
  };

  const resetFilter = () => {
    handleFilter({});
    setOrderFilterPreferences({});
    handlePageLimit({ target: { value: 10 } });
  };

  const handlePageRow = (event) => {
    handlePageLimit(event);
    setOrderFilterPreferences({ ...filter, pageLimit: event.target.value });
  };
  const setOrderFilterPreferences = (filteredArray) => {
    const userDetails = localStorage.getItem("userDetails");
    const userDetailsParse = JSON.parse(userDetails);
    axios
      .post("/api/preferences/set/order-filter", {
        orderFilter: filteredArray,
        user_id: userDetailsParse.user_id,
      })
      .then((response) => {
        console.log(response, "response");
      })
      .catch((err) => {
        swal(err);
      });
  };
  return (
    <>
      <button
        className="btn btn-primary"
        data-toggle="collapse"
        data-target="#demo"
      >
        Show/Hide Filter
      </button>
      <button
        className="ml-lg-5 ml-2 btn btn-primary "
        onClick={resetFilter}
        disabled={Object.keys(filter).length === 0 && pageLimit == 10}
      >
        Reset All Filters
      </button>
      <div id="demo" className="collapse">
        <div class="col-12  px-lg-3 px-1">
          <div className="row mt-3">
            {filterList?.map((filterItem) => (
              <div className="col-lg-3 col-xs-6  text-left ">
                <label className=" labelform h5 mt-4  text-left">
                  <b>{filterItem?.name}</b>
                </label>
                {filterItem?.type === "date" ? (
                  <DatePicker
                    className="NOpadding-right zero form-control fa fa-search"
                    onChange={(date) =>
                      handleFilterChange({
                        target: {
                          name: filterItem?.key,
                          value: date,
                        },
                      })
                    }
                    placeholderText="MM-DD-YYYY"
                    selected={
                      filter[filterItem?.key]
                        ? new Date(filter[filterItem?.key])
                        : ""
                    }
                  />
                ) : (
                  <MultiSelect
                    className="multi-select-1"
                    singleSelect={!filterItem.multiSelect}
                    key={filterItem?.key}
                    options={filterItem?.dataList?.map((data) => {
                      return { name: data, id: data };
                    })}
                    displayValue={"name"}
                    onSelect={(selectedList, selectedItem) =>
                      handleFilterChange({
                        target: {
                          name: filterItem.key,
                          value: selectedItem.name,
                        },
                      })
                    }
                    onRemove={(selectedList, selectedItem) =>
                      handleFilterChange({
                        target: {
                          name: filterItem.key,
                          value: selectedItem.name,
                        },
                      })
                    }
                    selectedValues={
                      filterItem.multiSelect
                        ? filter[filterItem?.key]?.map((data) => {
                            return { name: data, id: data };
                          })
                        : [
                            {
                              name: filter[filterItem?.key],
                              id: filter[filterItem?.key],
                            },
                          ]
                    }
                    showCheckbox
                    showArrow
                    style={{
                      multiselectContainer: {},
                      optinContainer: {},

                      option: {
                        display: "flex",
                        gap: "10px",
                      },
                      checkbox: {
                        marginRight: "11px!important;",
                      },
                      chips: {
                        maxWidth: "90%",
                        overflowX: "hidden",
                        whiteSpace: "break-spaces",
                      },
                      searchBox: {
                        fontWeight: "bold",
                        marginRight: "-10px",
                      },
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class="col-12 px-0 mt-5 row">
        <div className="col-md-2 col-lg-2 col-xs-4 text-left">
          <label className=" labelform h5  text-left">
            <b>Rows / Page</b>
          </label>
          <select
            onChange={handlePageRow}
            value={pageLimit}
            id="limitRange"
            name="limitRange"
            className="col-lg-12 col-md-12 col-sm-6 col-xs-12  noPadding form-control"
          >
            <option value="Not Selected" disabled>
              Select Row Limit
            </option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={1000}>1000</option>
            <option value={2000}>2000</option>
          </select>
        </div>
        <div className="col-lg-4 col-xs-8 pr-0 offset-lg-6 offset-0   text-left">
          <label className="h5 labelform text-left">
            <b>Search</b>
          </label>
          <input
            type="text"
            placeholder={`Search By ${filter["search"] ?? ""}`}
            onChange={handleFilterChange}
            className="NOpadding-right zzero form-control fa fa-search"
            id="tableSearch"
            name="search"
            value={filter["search"] ?? ""}
          />
        </div>
      </div>
    </>
  );
};

const TablePagination = ({
  currentPage,
  pages,
  setPage,
  pageLimit,
  handlePageLimit,
}) => {
  const numPages = Math.ceil(pages / pageLimit);

  const renderPageBlocks = () => {
    return (
      <>
        {currentPage !== 1 && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToPrevPage}>
              {currentPage - 1}
            </a>
          </li>
        )}
        <li className="page-item active" disabled>
          <a className="page-link" href="#">
            {currentPage}
          </a>
        </li>
        {currentPage < numPages && (
          <li className="page-item">
            <a className="page-link" href="#" onClick={goToNextPage}>
              {currentPage + 1}
            </a>
          </li>
        )}
      </>
    );
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < numPages) {
      setPage(currentPage + 1);
    }
  };

  const renderPrevPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(1)}>
          &#171;
        </a>
      </li>
    );
  };

  const renderNextPageBlocks = () => {
    return (
      <li className="page-item">
        <a className="page-link" href="#" onClick={() => setPage(numPages)}>
          &raquo;
        </a>
      </li>
    );
  };

  const renderTotals = () => {
    return (
      <li className="mx-2 col-8">
        <h6>{`Showing ${currentPage} of ${numPages}`} Pages</h6>
      </li>
    );
  };

  return (
    <nav aria-label="Page navigation" className="text-center align-middle mb-5">
      <ul className="pagination col-12 d-flex justify-content-center flex-direction-row">
        {renderTotals()}
        {renderPrevPageBlocks()}
        {renderPageBlocks()}
        {renderNextPageBlocks()}
      </ul>
    </nav>
  );
};
